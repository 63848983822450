
import ApiRequestService from '@/services/ApiRequestService'
import SnippetsService from '@/services/SnippetsService'
import StorageService, { LANGUAGE_KEY, USER_ID } from '@/services/StorageService'
import LoggerService from '@/services/UsersService'
import Multiselect from "vue-multiselect";
import {
  debounceTime,
  distinctUntilChanged,
  map,
} from "rxjs/operators";
import { Subject } from "rxjs";
import Vue from "vue";
import { DataEmitterService } from "@/services/DataEmiterService";
import DateUtil from '@/utils/DateUtil'
import UsersService from "@/services/UsersService";


export default {

  components: {
    Multiselect
  },
  data() {
    let snippets = SnippetsService.getSnippetsByNamespaceAndLanguage( 'Users', StorageService.getFromStorage( LANGUAGE_KEY ) );

    return {
      page: 0,
      pageCount: 0,
      itemsPerPage: 10,
      usersSnippet: snippets ?? {},
      usersList: [],
      usersPaginatedList: {},
      searchedUsersPaginatedList: {},
      searchedUsersList: [],
      paginatedUsersList: [],
      paginatedSearchedUsersList: [],
      rolesList: [],
      organizationsList: [],
      organizationsNamesList: [],
      loadedPages: [],
      searchedData: [],
      searchedDataOriginal: '',
      selectedOrganization: '',
      currentManagerOrganization: '',
      searchKey: '',
      searchField: '',
      dialog: false,
      dialogDelete: false,
      dialogUpgrade: false,
      submitted: false,
      editedIndex: -1,
      facilities: [],
      selectedFacility: '',
      editedItem: {
        userId: '',
        email: '',
        profilePhoto: '',
        roles: '',
        organizationId: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        password: '',
      },
      defaultItem: {
        userId: '',
        email: '',
        profilePhoto: '',
        roles: '',
        organizationId: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        password: '',
      },
      multiSelectRoles: [],
      sortBy: 'userId',
      editedPrefix: '',
      searchResults: '',
      search$: new Subject(),
      sortDesc: true,
      errorKey: '',
      pagesCount: 1,
      currentPage: 1,
      disableKeyField: true,
      oldUser: {},
      addressesDataList: [],
      itemsPerPageOptions: [ 0 ],
      userData: { users: [], count: Number },
      totalPassengers: 0,
      numberOfPages: 0,
      passengers: [],
      userType:
        this.$route.path.includes( '/sanity-check-admins' ) ? 'sanity-check-admins' :
        this.$route.path.includes( '/users' ) ? 'users' :
        this.$route.path.includes( '/admin-users' ) ? 'admin-users' :
        this.$route.path.includes( '/facility-managers' ) ? 'facility-managers' :
        this.$route.path.includes( '/juries' ) ? 'juries' :
        this.$route.path.includes( '/advanced-admin-users' ) ? 'advanced-admin-users' : '',
      canOverwrite: true,
      loading: true
    }
  },

  computed: {

    isFacilityManager(){
      console.log('this.editedItem', this.editedItem)
      const isFacilityManager = this.editedItem && this.editedItem?.roles && Array.isArray(this.editedItem?.roles) ? this.editedItem?.roles?.find(item => item.name === 'FacilityManager') : this.editedItem?.roles?.includes('FacilityManager');
      if(isFacilityManager){
        ApiRequestService.getRequest( `api/admin-facilities` ).then(data => {
          this.facilities = data;
          console.log('this.facilities' ,this.facilities)
        });
      }
      return isFacilityManager;
    },

    formTitle() {
      return this.editedIndex === -1 ? this.usersSnippet.add_user : this.usersSnippet.edit_user
    },

    headers() {
      return [
        { text: this.usersSnippet.id, value: 'userId', width: '0px', sortable: true },
        { text: this.usersSnippet.roles, value: 'roles', width: '0px', sortable: true },
        {
          text: this.usersSnippet.organization,
          value: 'organization',
          width: '0px',
          sortable: true
        },
        { text: this.usersSnippet.email, value: 'email', width: '0px', sortable: true, },
        { text: this.usersSnippet.firstName, value: 'firstName', width: '0px', sortable: true },
        { text: this.usersSnippet.lastName, value: 'lastName', width: '0px', sortable: true },
        {
          text: this.usersSnippet.phoneNumber,
          value: 'phoneNumber',
          width: '0px',
          sortable: true
        },
        { text: this.usersSnippet.membershipStatus, value: 'membershipStatus', width: '0px', sortable: true },
        { text: this.usersSnippet.createDate, value: 'createDate', width: '0px', sortable: true },
        { text: this.usersSnippet.actions, value: 'actions', width: '0px', sortable: false },
      ]
    },
  },

  watch: {

    dialog( val ) {
      val || this.close()
    },

    dialogDelete( val ) {
      val || this.closeDelete()
    },

    dialogUpgrade( val ) {
      val || this.closeUpgrade()
    },

    async searchKey( searchText ) {
      if ( searchText ) {
        this.search$.next( searchText );
        this.searchField = searchText
      } else {
        this.search$.next( '' );
        this.searchField = ''
        this.searchedData = [];
      }
    },

  },
  beforeRouteLeave( to, from, next ) {
    this.saveUsersDataToSessionStorage();
    DataEmitterService.$emit( 'route-change')

    if ( this.$route.path.includes( 'users' ) || this.$route.path.includes( 'admin-users' ) ) {
      return next();
    }
    // StorageService.deleteUserDataFromStorage();
    next()
  },

  async mounted() {
    this.searchAction();

    window.addEventListener( 'beforeunload', this.saveUsersDataToSessionStorage );
    let storageUsers = this.getUsersDataFromSessionStorage();
    if ( storageUsers?.data ) {
      this.userData = storageUsers;
      this.loadedPages.push( +storageUsers.currentPage );
      await this.getUsersPaginated();
      // }
    } else {
      await this.getUsersApi( this.userType );
    }


    await this.getRolesApi();

    await this.getOrganizationListIfHaveAccess();

    this.loading = false;

  },

  methods: {
    customSort( items ) {
      return items
    },

    async sortTable() {
      await Promise.resolve();
      this.changePage(this.currentPage ?? 1);
    },

    getSortQuery() {
      if (this.sortBy) {
        return `&sortBy=${this.sortBy}&direction=${this.sortDesc ? 'DESC' : 'ASC'}`;
      }

      return '';
    },

    saveUsersDataToSessionStorage() {
      //TODO: remove
      return
      if ( this.canOverwrite ) {
        sessionStorage.setItem( 'users_paginated_list', JSON.stringify( this.userData ) )
        sessionStorage.setItem( 'users_searched_text', JSON.stringify( this.searchField ) )
        sessionStorage.setItem( 'users_searched_list', JSON.stringify( this.searchedDataOriginal ) )
        sessionStorage.setItem( 'users_paginated_list_save_date', Date.now().toString() )
      }
    },

    getUsersDataFromSessionStorage() {
      let usersData = JSON.parse( sessionStorage.getItem( 'users_paginated_list' ) );
      this.searchKey = JSON.parse( sessionStorage.getItem( 'users_searched_text' ) );
      if ( this.searchKey ) {
        usersData = {};
      }
      this.currentPage = usersData?.currentPage ? +usersData.currentPage : 1;
      return usersData;
    },

    searchAction() {
      this.search$ = new Subject();

      this.search$.pipe(
        map( text => text?.toString()?.toLowerCase() ),
        debounceTime( 1000 ),
        distinctUntilChanged()
      ).subscribe( async ( term ) => {
        this.currentPage = 1;

        if ( this.searchField === '' ) {
          await this.getUsersPaginated();
          return
        }

        let data = await this.searchText( term );
        await this.getSearchedUsersPaginated( data );
      } )
    },

    searchText( term ) {
      return ApiRequestService.getRequest( `api/admin-users?searchText=${ term }&onlyAdmin=${ this.userType }${this.getSortQuery()}` );
    },

    async changePage( page ) {
      this.usersPaginatedList = {};
      this.searchedUsersPaginatedList = {};
      this.paginatedSearchedUsersList.length = 0;
      this.paginatedUsersList.length = 0;
      this.usersList.length = 0;
      this.currentPage = page;

      if ( this.searchField ) {
        this.searchedDataOriginal = await ApiRequestService.getRequest( `api/admin-users?page=${ page }&searchText=${ this.searchField }&onlyAdmin=${ this.userType }${this.getSortQuery()}` );

        if ( this.searchedDataOriginal?.data?.length ) {
          await this.setSearchedUsersToUsersList( this.searchedDataOriginal.data );
        }
        return;
      }

      this.userData = await ApiRequestService.getRequest( `api/admin-users?page=${ page }&onlyAdmin=${ this.userType }${this.getSortQuery()}` );
      this.loadedPages = [this.currentPage];

      if ( this.userData?.data?.length ) {
        await this.setUsersToUsersList( this.userData.data );
      }
    },

    async getRolesApi() {
      let rolesData = await ApiRequestService.getRequest( 'api/roles' );
      await this.setRolesList( rolesData );
    },

    async getUsersApi( onlyAdmin = 'users' ) {
      this.userData = await ApiRequestService.getRequest( `api/admin-users?onlyAdmin=${ onlyAdmin }${this.getSortQuery()}` );
      DataEmitterService.$emit( 'route-change')

      this.usersPaginatedList = {}
      this.usersList = []
      await this.getUsersPaginated();
    },

    async getUsersPaginated() {
      let usersCount = this.userData.totalElements;
      this.pagesCount = Math.ceil( this.userData.totalElements / 10 )
      if ( usersCount ) {
        await this.setUsersToUsersList( this.userData.data );
      }
    },

    async getSearchedUsersPaginated( data ) {
      this.pagesCount = Math.ceil( data.totalElements / 10 )

      this.searchedUsersList = [];
      this.paginatedSearchedUsersList.length = 0;
      this.searchedUsersPaginatedList = {};
      await this.setSearchedUsersToUsersList( data.data );

      this.searchedDataOriginal = data;
      this.searchedData = this.getObjClone( this.searchedUsersList )
    },

    // async selectPerPageCount( event ) {
    //   if ( event === this.userData?.data?.length ) {
    //     return
    //   }
    //
    //   // this.userData = await ApiRequestService.getRequest( `api/users?offset=${ this.userData?.data?.length }&limit=${ event + 1 }` );
    //   let page = Math.ceil( event / 10 );
    //   this.userData = await ApiRequestService.getRequest( `api/users?page=${ page }` );
    //
    //   if ( this.userData?.data?.length ) {
    //     await this.setUsersToUsersList( this.userData.data );
    //   }
    // },

    async setUsersToUsersList( userData ) {
      userData.forEach( ( user, index ) => {
        let existsIndex = this.usersList.findIndex( item => item.userId === user?.userId );

        let userData = {
          userId: user?.userId,
          roles: user?.roles?.join( ', ' ),
          email: user?.email,
          profilePhoto: user?.photo,
          firstName: user?.firstName,
          phoneNumber: user?.phoneNumber,
          organization: user?.organization?.name,
          lastName: user?.lastName,
          membershipStatus: user?.requestedOrganization ? this.usersSnippet.pending : this.usersSnippet.approved,
          createDate: this.getToLocaleDate( user?.createDate ),
        };

        if ( existsIndex !== -1 ) {
          Vue.set( this.usersList, existsIndex, userData )

          let values = this.usersPaginatedList[this.currentPage] ? this.usersPaginatedList[this.currentPage].values() : [];
          let usersPaginatedListClone = [ ...values ];
          usersPaginatedListClone.forEach( item => {
            if ( item?.userId === userData?.userId ) {
              this.usersPaginatedList[this.currentPage].delete( item )
              this.usersPaginatedList[this.currentPage].add( userData )
            }
          } );
          return;
        }

        Vue.set( this.usersList, this.usersList.length, userData )

        if ( !this.usersPaginatedList[this.currentPage] ) {
          this.usersPaginatedList[this.currentPage] = new Set();
        }
        if ( this.usersPaginatedList[this.currentPage].size < 10 ) {
          this.usersPaginatedList[this.currentPage].add( userData );
        }
      } )

      if ( this.usersPaginatedList[this.currentPage] ) {
        this.paginatedUsersList = this.getObjClone( [ ...this.usersPaginatedList[this.currentPage] ] )
      }
    },

    async setSearchedUsersToUsersList( data ) {
      data.forEach( ( user, index ) => {
        let existsIndex = this.paginatedSearchedUsersList.findIndex( item => item.userId === user?.userId );
        let userData = {
          userId: user?.userId,
          roles: Array.isArray( user?.roles ) ? user?.roles?.join( ', ' ) : user?.roles,
          email: user?.email,
          firstName: user?.firstName,
          phoneNumber: user?.phoneNumber,
          organization: user?.organization?.name,
          lastName: user?.lastName,
          membershipStatus: user?.requestedOrganization ? this.usersSnippet.pending : this.usersSnippet.approved,
          createDate: this.getToLocaleDate( user?.createDate ),
        };

        if ( existsIndex !== -1 ) {
          let searchedUsersPaginatedListClone = this.getObjClone( this.searchedUsersPaginatedList[this.currentPage] )

          searchedUsersPaginatedListClone.forEach( ( item, index ) => {
            if ( item?.userId === userData?.userId ) {
              this.searchedUsersPaginatedList[this.currentPage][index] = userData
            }
          } );
          return;
        }

        if ( !this.searchedUsersPaginatedList[this.currentPage] ) {
          this.searchedUsersPaginatedList[this.currentPage] = [];
        }
        if ( this.searchedUsersPaginatedList[this.currentPage].length < 10 ) {
          this.searchedUsersPaginatedList[this.currentPage].push( userData );
        }
      } )

      if ( this.searchedUsersPaginatedList[this.currentPage] ) {
        this.paginatedSearchedUsersList = this.getObjClone( this.searchedUsersPaginatedList[this.currentPage] )
      }
    },

    getToLocaleDate( date ) {
      return DateUtil.getToLocaleDate(date, true)
    },

    addUserToUserList( data ) {
      let newUserData = {
        userId: data.userId,
        email: data.email,
        roles: data.roles,
        firstName: data.firstName,
        lastName: data.lastName,
        organization: data?.organization?.name,
        phoneNumber: data.phoneNumber,
        createDate: this.getToLocaleDate( data.createDate ),
      };

      if ( this.searchField ) {
        let existsIndex = this.paginatedSearchedUsersList?.findIndex( user => user.userId === newUserData.userId );
        if ( existsIndex !== -1 ) {
          this.paginatedSearchedUsersList[existsIndex] = newUserData;
        } else {
          this.paginatedSearchedUsersList.push( newUserData );
          this.searchedDataOriginal.data.totalElements++;
        }

        this.setSearchedUsersToUsersList( this.paginatedSearchedUsersList )

      } else {
        let existsIndex = this.userData?.data?.findIndex( user => user.userId === newUserData.userId );
        if ( existsIndex !== -1 ) {
          this.userData.data[existsIndex] = newUserData;
        } else {
          this.userData.data.push( newUserData );
          this.userData.totalElements++;
        }

        this.setUsersToUsersList( this.userData.data )
      }
    },

    async getChangedData( oldUser ) {
      const dataFormUpdate = {}
      for ( let [ key, value ] of Object.entries( this.editedItem ) ) {
        if ( oldUser[key] === this.editedItem[key] ) {
          continue
        }


        dataFormUpdate[key] = this.editedItem[key]
      }
      const formData = new FormData();
      formData.append( "file", dataFormUpdate?.profilePhoto, dataFormUpdate?.profilePhoto.name );

      let data = await ApiRequestService.postRequest( 'api/user-photo', formData );
      dataFormUpdate.photoId = data?.id;

      return dataFormUpdate
    },

    async updatedUserRequest( oldUser ) {
      const dataFormUpdate = await this.getChangedData( oldUser );

      const updatedUser = await ApiRequestService.updateRequest( `api/admin-users/${ this.editedItem.userId }`, dataFormUpdate )

      if ( updatedUser.errorMessage ) {
        this.close()
        return
      }

      if ( updatedUser ) {

        if ( this.searchField ) {
          this.paginatedSearchedUsersList = this.paginatedSearchedUsersList.filter( listItem => listItem.userId !== updatedUser.userId )
        } else {
          this.paginatedUsersList = this.paginatedUsersList.filter( listItem => listItem.userId !== updatedUser.userId )
        }
        this.addUserToUserList( updatedUser )
      }

      this.close()
    },

    dataIsValid() {
      const nameIsValid = this.userIsValid( this.editedItem?.email?.trim() )
      const facilityIsValid = this.facilityIsValid( this.selectedFacility ? this.selectedFacility[0]: {} )
      const isNotValid = []

      if ( nameIsValid !== true ) {
        isNotValid.push( nameIsValid )
      }
      if ( this.selectedFacility?.length && facilityIsValid !== true ) {
        isNotValid.push( facilityIsValid )
      }


      if ( isNotValid.length ) {
        return isNotValid
      }

      return true
    },


    userIsValid( v ) {
      return ( v && v.length > 0 ) || this.usersSnippet.required
    },

    facilityIsValid( v ) {
      return ( v && !Number.isNaN(v.id) ) || this.usersSnippet.facility_is_required
    },

    editItem( item ) {
      let paginatedList = this.searchField ? this.paginatedSearchedUsersList : this.paginatedUsersList;
      let list = this.searchField ? this.searchedUsersList : this.usersList;
      this.editedIndex = paginatedList.findIndex( user => user.userId === item.userId );
      this.listEditedIndex = list.findIndex( user => user.userId === item.userId );
      this.editedItem = this.getObjClone( paginatedList[this.editedIndex] );

      if ( this.editedItem?.roles ) {
        if ( !Array.isArray( this.editedItem?.roles ) ) {
          this.editedItem.roles = this.editedItem.roles.split( ', ' );
        }

        let formattedRoles = [];
        this.editedItem.roles.forEach( role => {
          formattedRoles.push( { name: role } )
        } );

        this.editedItem.roles = formattedRoles;
      }

      if ( this.editedItem.organization ) {
        this.selectOrganizationById( this.editedItem.organization )
      }

      this.dialog = true
      this.disableKeyField = true
      this.oldUser = this.getObjClone( this.editedItem )
    },

    deleteItem( item ) {
      let list = this.searchField ? this.paginatedSearchedUsersList : this.paginatedUsersList;
      this.editedIndex = list.findIndex( user => user.userId === item.userId );
      this.editedItem = this.getObjClone( list[this.editedIndex] );
      this.dialogDelete = true
    },

    upgradeItem( item ) {
      this.editedIndex = this.usersList.indexOf( item )
      this.dialogUpgrade = true
    },


    deleteItemConfirm() {
      let paginatedList = this.searchField ? this.paginatedSearchedUsersList : this.paginatedUsersList;
      let list = this.searchField ? this.searchedUsersList : this.usersList;
      paginatedList.splice( this.editedIndex, 1 )
      list.splice( this.listEditedIndex, 1 )

      ApiRequestService.deleteRequest( `api/admin-users/${ this.editedItem.userId }` ).then()

      this.closeDelete()
    },

    setPhotos( file ) {
      if ( file ) {
        this.editedItem.profilePhoto = file;
      }
    },

    getObjClone( obj ) {
      return JSON.parse( JSON.stringify( obj ) )
    },

    close() {
      this.dialog = false
      this.submitted = false
      this.errorKey = ''

      /**
       * if action is edit, set to form old data
       */
      let data
      if ( this.editedIndex !== -1 ) {
        data = this.getObjClone( this.oldUser )
      } else {
        data = this.getObjClone( this.defaultItem )
      }

      for ( let [ key, value ] of Object.entries( data ) ) {
        if ( value && typeof value === 'object' ) {
          for ( let [ valueKey, valueValue ] of Object.entries( value ) ) {
            this.$set( value, valueKey, valueValue )
          }
        } else {
          this.$set( this.editedItem, key, value )
        }
      }

      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) }
        this.editedIndex = -1
      } )
    },

    closeDelete() {
      this.dialogDelete = false
      this.submitted = false
      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) }
        this.editedIndex = -1
      } )
    },

    closeUpgrade() {
      this.dialogUpgrade = false
      this.submitted = false
      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) }
        this.editedIndex = -1
      } )
    },

    setNewUserToUsersList( userData ) {
      if ( userData ) {

        this.addUserToUserList( userData )

        this.close()
      } else if ( userData.errorMessage ) {
      }
    },

    async saveUser( roles ) {
      let isValid = this.dataIsValid()
      if ( isValid !== true ) {
        return
      }
      UsersService.setEmail( this.editedItem?.email )
      const creationData = { ...this.editedItem, roles }

      let dataToCreate = {};
      for ( let [ key, value ] of Object.entries( creationData ) ) {
        if ( key === 'organization' ) {
          if ( !value && !this.currentManagerOrganization?.id ) {
            continue;
          }
          value = this.currentManagerOrganization?.id ?? value
        }
        if ( value ) {
          dataToCreate[key] = value;
        }
      }
      if(this.selectedFacility){
        dataToCreate.facilities = this.selectedFacility?.map(item => item.id);
      }
      const userData = await ApiRequestService.postRequest( 'api/admin-users', dataToCreate );

      if ( userData?.errorMessage ) {
        this.errorKey = userData?.errorMessage?.split( ',' );
        return;
      }
      if ( !userData ) {
        return;
      }
      this.setNewUserToUsersList( userData )

      UsersService.clearAllData()
    },

    async updateUser() {
      let isValid = this.dataIsValid()
      if ( isValid !== true ) {
        return
      }

      await this.updatedUserRequest( this.oldUser )
    },

    save() {
      this.submitted = true
      const roles = this.selectRoles( this.editedItem.roles );
      // save or edit
      if ( this.editedIndex === -1 ) {
        this.saveUser( roles )
      } else {
        this.updateUser()
      }
    },

    openEdit( user ) {
      if ( user && user.userId ) {
        sessionStorage.setItem('last-opened-user-type', this.userType);
        this.$router.push( `user-edit/${ user.userId }` )
      }
    },

    setRolesList( rolesData ) {
      this.rolesList = rolesData.length ? rolesData : [];
      this.rolesList?.forEach( item => {
        if ( item ) {
          this.multiSelectRoles.push( {
            name: item
          } )
        }
      } );
    },

    setOrganizationsList( organizationsData ) {
      this.organizationsList = organizationsData;
      this.organizationsList?.forEach( organization => this.organizationsNamesList.push( organization.name ) )
    },

    selectRoles( roles ) {
      let result = [];
      roles?.forEach( role => {
        result.push( role.name );
      } );
      return result;
    },

    selectOrganization( organization ) {
      if ( !this.organizationsList?.length ) {
        return;
      }
      let selectedOrganization = this.organizationsList?.find( item => item.name === organization );
      this.selectedOrganization = selectedOrganization.name;
      this.editedItem.organizationId = selectedOrganization?.id;
    },

    selectOrganizationById( organization ) {
      if ( !this.organizationsList?.length ) {
        return;
      }
      let selectedOrganization = this.organizationsList?.find( item => item.name === organization );
      this.selectedOrganization = selectedOrganization.name;
      this.editedItem.organizationId = selectedOrganization?.id;
    },

    async getOrganizationListIfHaveAccess() {
      let userId = StorageService.getFromStorage( USER_ID );
      let currentUser = await ApiRequestService.getRequest( `api/admin-users/${ userId }` );
      if ( currentUser?.roles?.includes( 'CompanyManager' ) && !currentUser?.roles?.includes( 'Admin' ) ) {
        this.currentManagerOrganization = currentUser.organization;
        return;
      }
      let organizationsData = await ApiRequestService.getRequest( 'api/organizations' );
      await this.setOrganizationsList( organizationsData.data );
    }
  },
}

