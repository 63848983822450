export default class UsersService {
  static SELECTED_TYPE = ''
  static SELECTED_TYPE_DATA = []
  static SELECTED_KEY = ''
  static SELECTED_NAME = ''
  static SELECTED_DOMAIN = ''



  static setKey(key) {
    if (!key) {
      console.log('wrong key!')
      return
    }

    UsersService.SELECTED_KEY = key
    return true
  }

  static getSelectedKey() {
    return UsersService.SELECTED_KEY
  }

  static setEmail(email) {
    if (!email) {
      console.log('wrong email!')
      return;
    }

    UsersService.SELECTED_EMAIL = email
    return true
  }

  static getSelectedEmail() {
    return UsersService.SELECTED_EMAIL
  }


  static clearAllData() {
    UsersService.SELECTED_TYPE = ''
    UsersService.SELECTED_TYPE_DATA = []
    UsersService.SELECTED_KEY = ''
    UsersService.SELECTED_NAME = ''
    UsersService.SELECTED_DOMAIN = ''
  }
}
